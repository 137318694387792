import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const SectionWrapper = styled.section`
  margin-top: 90px;
  overflow: hidden;
  margin-bottom: 126px;
  @media screen and (max-width: 990px) {
    margin-bottom: 90px;
    margin-top: 80px;
  }
  @media screen and (max-width: 568px) {
    margin-bottom: 68px;
  }

  .container {
    position: relative;
  }

  .content-wrapper {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    width: 625px;
    margin-left: 140px;
    @media screen and (max-width: 1220px) {
      margin-left: 40px;
    }
    @media screen and (max-width: 990px) {
      width: 100%;
      margin-left: 40px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-left: 35px;
    }

    .text {
      font-size: ${themeGet("fontSizes.5")}px;
      line-height: 28px;
      font-weight: 400;
      margin-top: 16px;
      color: ${themeGet("colors.text")};
      @media screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
        max-width: 500px;
      }
      @media screen and (max-width: 568px) {
        margin-top: 8px;
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
        max-width: 300px;
      }
    }
  }
`;

export default SectionWrapper;
