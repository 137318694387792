import * as Yup from "yup";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import FormControl from "@mui/material/FormControl";
import Heading from "common/components/Heading";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Select from "@mui/material/Select";
import Text from "common/components/Text";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { graphql, useStaticQuery } from "gatsby";
import Alert from "@mui/material/Alert";

import SectionWrapper, {
  FormWrapper,
  SectionHeader,
} from "./contactForm.style";

const ContactForm = () => {
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        CONTACT_CONTACT_FORM {
          title
          subtitle
          text
        }
      }
    }
  `);
  const { title, subtitle, text } = data.ertCreditJson.CONTACT_CONTACT_FORM;
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [userMessage, setUserMessage] = React.useState('');

  //Data
  const initialValues = {
    name: "",
    company: "",
    industry: "",
    phone: "",
    email: "",
    employees2020: "",
    employees2021: "",
  };

  const employees2020 = [
    { label: "1-5", value: "1-5" },
    { label: "6-25", value: "6-25" },
    { label: "26-50", value: "26-50" },
    { label: "51-75", value: "51-75" },
    { label: "76-100", value: "76-100" },
    { label: "100+", value: "100+" }
  ];

  const employees2021 = [
    { label: "1-5", value: "1-5" },
    { label: "6-25", value: "6-25" },
    { label: "26-50", value: "26-50" },
    { label: "51-75", value: "51-75" },
    { label: "76-100", value: "76-100" },
    { label: "100+", value: "100+" },
  ];

  //validation schema
  let validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    company: Yup.string().required("Required"),
    industry: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        source: "website",
        ...values})
    };

    const response = await fetch('/api/contact/add-contact', requestOptions);
    const data = await response.json();
    if (data.firestore.id) {
      setSuccess(true);
    }
    console.log(data);
    resetForm({});
  };

  return (
    <>
      <SectionWrapper>
        <Container width="990px" className="container-header">
          <SectionHeader>
            <Heading as="h5" content={subtitle} />
            <Heading as="h2" content={title} />
          </SectionHeader>
          <Text content={text} className="text" />
        </Container>
        {success && <Container width="990px">
          <Alert sx={{ margin: "10px"}} severity="success">
            Thank you for contacting us, your message has been received, we will contact
            you within 30 minutes</Alert>
        </Container> }
        <Container width="990px" className="container-header">
          <FormWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ dirty, isValid, values, handleChange, handleBlur }) => {
                return (
                  <Form className="form-wrapper">
                    <div className="form-fields-part-a">
                      <Field
                        InputLabelProps={{ shrink: true }}
                        id="standard-basic"
                        label="FULL NAME"
                        name="name"
                        value={values.name}
                        variant="standard"
                        fullWidth
                        placeholder="Enter your full name"
                        component={TextField}
                      />

                      <Field
                        InputLabelProps={{ shrink: true }}
                        id="standard-basic"
                        label="COMPANY NAME"
                        name="company"
                        value={values.company}
                        variant="standard"
                        fullWidth
                        placeholder="Enter your company name"
                        component={TextField}
                      />
                      <Field
                        InputLabelProps={{ shrink: true }}
                        id="standard-basic"
                        label="INDUSTRY"
                        name="industry"
                        value={values.industry}
                        variant="standard"
                        fullWidth
                        placeholder="Enter your industry"
                        component={TextField}
                      />
                      <Field
                        InputLabelProps={{ shrink: true }}
                        id="standard-basic"
                        label="PHONE NUMBER"
                        name="phone"
                        value={values.phone}
                        variant="standard"
                        fullWidth
                        placeholder="Enter your phone number"
                        component={TextField}
                      />
                    </div>
                    <div className="form-fields-part-b">
                      <Field
                        InputLabelProps={{ shrink: true }}
                        id="standard-basic"
                        label="EMAIL"
                        name="email"
                        value={values.email}
                        variant="standard"
                        fullWidth
                        placeholder="Enter your email address"
                        component={TextField}
                      />
                      <Field
                        InputLabelProps={{ shrink: true }}
                        id="standard-basic"
                        label="Message"
                        name="message"
                        value={values.message}
                        variant="standard"
                        fullWidth
                        placeholder="What can we help you with?"
                        multiline
                        rows={3}
                        maxrows={5}
                        component={TextField}
                      />
                    </div>
                    <div className="form-fields-part-c">
                      <FormControl
                        variant="standard"
                        sx={{ minWidth: 120 }}
                        className="select-field"
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          AVERAGE # OF EMPLOYEES IN 2020
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="AVERAGE # OF EMPLOYEES IN 2020"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.employees2020}
                          name="employees2020"
                        >
                          <MenuItem>None</MenuItem>
                          {employees2020.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="standard"
                        sx={{ minWidth: 120 }}
                        className="select-field"
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          AVERAGE # OF EMPLOYEES IN 2021
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Average # OF EMPLOYEES IN 2021"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.employees2021}
                          name="employees2021"
                        >
                          <MenuItem>None</MenuItem>
                          {employees2021.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <Button
                      title="Send Message"
                      variant="textButton"
                      disabled={!dirty || !isValid}
                      type="submit"
                    />
                  </Form>
                );
              }}
            </Formik>
          </FormWrapper>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default ContactForm;
