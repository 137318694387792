import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import React from "react";
import Text from "common/components/Text";
import { graphql, useStaticQuery } from "gatsby";
import { useWindowSize } from "common/hooks/useWindowSize";

import SectionWrapper from "./heroAlt.style";

const HeroAlt = ({ title, text }) => {
  const { width } = useWindowSize();

  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        HERO_ALT {
          imageBg {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          imageBgMobile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
        }
      }
    }
  `);
  const { imageBg, imageBgMobile } = data.ertCreditJson.HERO_ALT;

  return (
    <>
      <SectionWrapper>
        <Container width="1470px">
          {width > 568 ? (
            <GatsbyImage
              src={
                (imageBg !== null) | undefined
                  ? imageBg.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Background"
            />
          ) : (
            <GatsbyImage
              src={
                (imageBgMobile !== null) | undefined
                  ? imageBgMobile.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Background"
            />
          )}
          <div className="content-wrapper">
            <Heading as="h1" content={title} />
            <Text content={text} className="text" />
          </div>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default HeroAlt;
