import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const SectionWrapper = styled.section`
  overflow: hidden;

  .container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    @media screen and (max-width: 990px) {
      margin-bottom: 55px;
    }
    @media screen and (max-width: 568px) {
      margin-bottom: 34px;
      flex-direction: column;
    }
  }

  .text {
    font-size: ${themeGet("fontSizes.5")}px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0px;
    max-width: 530px;
    color: ${themeGet("colors.text")};
    @media screen and (max-width: 990px) {
      font-size: ${themeGet("fontSizes.2")}px;
      line-height: 20px;
    }
    @media screen and (max-width: 568px) {
      font-size: ${themeGet("fontSizes.2")}px;
      line-height: 20px;
      text-align: center;
      margin-top: 10px;
    }
  }
`;

export const SectionHeader = styled.header`
  min-width: 340px;
  max-width: 340px;

  h2 {
    font-size: ${themeGet("fontSizes.13")}px;
    line-height: 50px;
    margin-bottom: 0px;
    @media screen and (max-width: 990px) {
      font-size: ${themeGet("fontSizes.10")}px;
      line-height: 35px;
    }
    @media screen and (max-width: 568px) {
      text-align: center;
      font-size: ${themeGet("fontSizes.10")}px;
    }
  }
  h5 {
    color: ${themeGet("colors.primary")};
    @media screen and (max-width: 568px) {
      text-align: center;
    }
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  border: 6px solid #e3f2f3;
  box-shadow: 0px 97.4px 250px rgba(0, 0, 0, 0.02);
  padding: 60px 69px;
  @media only screen and (max-width: 990px) {
    padding: 40px 45px;
  }
  @media only screen and (max-width: 568px) {
    padding: 24px 20px;
    border: 3px solid #e3f2f3;
  }

  .form-fields-part-a {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 40px;
    @media screen and (max-width: 990px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 568px) {
      grid-template-columns: 1fr;
    }
  }

  .form-fields-part-b {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px 40px;

    @media screen and (max-width: 568px) {
      grid-template-columns: 1fr;
    }
  }

  .form-fields-part-c {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 40px;
    @media screen and (max-width: 568px) {
      grid-template-columns: 1fr;
    }
  }

  .reusecore__button {
    width: 100%;
    margin-top: 40px;
    @media screen and (max-width: 568px) {
      margin-top: 20px;
      font-size: 15px;
    }
    &:disabled {
      background-color: #eceff1;
      color: #b9b9b9;
      box-shadow: none;
    }
  }

  .MuiInputLabel-standard {
    font-family: "Poppins";
    font-weight: 600;
    font-size: ${themeGet("fontSizes.7", "15")}px;
    letter-spacing: -0.5px;
    @media screen and (max-width: 568px) {
      font-size: ${themeGet("fontSizes.6", "15")}px;
    }
  }

  .MuiInput-input {
    font-size: ${themeGet("fontSizes.4", "15")}px;
    color: #9a9ea1;
    padding: 15px 0 5px;
    font-family: "Poppins";
    letter-spacing: -0.5px;
    @media screen and (max-width: 568px) {
      font-size: ${themeGet("fontSizes.2", "15")}px;
      padding: 10px 0 6px;
    }
  }

  #demo-simple-select-standard-label {
    font-size: 16px;
    @media screen and (max-width: 568px) {
      font-size: ${themeGet("fontSizes.3", "15")}px;
    }
  }

  .MuiFormControl-root {
    margin: 0px;
  }

  .MuiInput-root:before {
    border-bottom: 1.5px solid #eeefef;
  }

  label.Mui-focused {
    color: #69a2b1;
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid #69a2b1;
  }

  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #69a2b1;
  }

  .MuiInput-root {
    padding: 0px;
  }

  .select-field {
    @media screen and (max-width: 568px) {
      margin-top: -16px;
    }
  }

  .label-long {
    font-family: "Poppins";
    font-weight: 600;
    font-size: ${themeGet("fontSizes.4", "15")}px;
    color: #797878;
    @media screen and (max-width: 568px) {
      font-size: ${themeGet("fontSizes.3", "15")}px;
    }
  }

  .MuiInputLabel-root.Mui-disabled {
    color: #797878;
  }

  .MuiSvgIcon-root {
    top: calc(33% - 0.5em);
  }
`;

export default SectionWrapper;
