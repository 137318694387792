import * as React from "react";
import ContactForm from "containers/ContactForm";
import HeroAlt from "containers/HeroAlt";
import Layout from "common/components/Layout";
import Seo from "components/seo";

const ContactPage = ({ location }) => {
  return (
    <Layout location={location.pathname}>
      <Seo title="Contact" />
      <HeroAlt
        title="Contact"
        text="We are founded by an expert team of payroll and tax specialists coupled with technology leaders who are on a mission to make it very simple for businesses to claim their Employee Retention Credit."
      />
      <ContactForm />
    </Layout>
  );
};

export default ContactPage;
